import React from 'react'

const Alert = (props) => {
    const { alertType, alertMessage } = props
    return (
        <div>
            {props.alertType &&
                <div className={`alert alert-${alertType} alert-dismissible fade show`} role="alert">
                    <strong>{alertType}!</strong> {alertMessage}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            }
        </div>
    )
}

export default Alert